'use strict';

var processInclude = require('base/util');

$(document).ready(function () {
    processInclude(require('./search/search'));
    processInclude(require('./product/wishlistHeart'));
    processInclude(require('./product/compare'));
    processInclude(require('./productTile'));
    processInclude(require('./components/playAudio'));
    processInclude(require('./storeLocator/storeLocator'));
});
