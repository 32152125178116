'use strict';

/* global history */

/** {@link github:taurgis/plugin_filternavigation} **/

const Search = require('base/search/search');

var $searchResults = $('.search-results');
var $refinementBar = $searchResults.find('.refinement-bar');

/**
 * Update DOM elements with Ajax results
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 * @return {undefined}
 */
function updateDom($results, selector) {
    var $updates = $results.find(selector);
    $(selector).empty().html($updates.html());
}

/**
 * Keep refinement panes expanded/collapsed after Ajax refresh
 *
 * @param {Object} $results - jQuery DOM element
 * @return {undefined}
 */
function handleRefinements($results) {
    let refineColor;
    $('.refinement.active').each(function () {
        const $this = $(this);
        let searchClass = '.' + $this[0].className.replace('active', '').trim().replace(/\s+/g, '.');
        refineColor = searchClass.includes('refinement-color');
        const $activeDiv = $results.find(searchClass);
        $activeDiv.addClass('active');
        $activeDiv.find('button.title').attr('aria-expanded', 'true');
    });

    updateDom($results, '.refinements');
    const selectedCount = document.querySelectorAll('.refinement-label.selected').length;
    if (refineColor && selectedCount > 1) {
        resetTitle();
    }
}

function resetTitle() {
    let titleElement = document.querySelector('title');
    let catNameText = $('span.catName').text();
    let newTittle = catNameText + " | Build Your Own Today | Build A Bear";
    titleElement.textContent = newTittle;
}

/**
 * Parse Ajax results and updated select DOM elements
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function parseResults(response) {
    var $results = $(`<div>${response}</div>`);
    var specialHandlers = {
        '.refinements': handleRefinements
    };

    // Update DOM elements that do not require special handling
    [
        '.grid-header',
        '.header-bar',
        '.header.page-title',
        '.product-grid',
        '.show-more',
        '.filter-bar'
    ].forEach(function (selector) {
        updateDom($results, selector);
    });

    Object.keys(specialHandlers).forEach(function (selector) {
        specialHandlers[selector]($results);
    });
}

/**
 * Update sort option URLs from Ajax response
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function updateSortOptions(response) {
    const $tempDom = $('<div>').append($(response));
    const sortOptions = $tempDom.find('.grid-footer').data('sort-options').options;
    sortOptions.forEach(function (option) {
        $('option.' + option.id).val(option.url);
    });
}

function handleRefinementEvents() {
    $('.show-refinement a').off('click').on('click', function () {
        const $this = $(this);
        const $toggleRefinements = $this.closest('ul').find('.refinement-hide-check');
        $toggleRefinements.toggleClass('d-none');
        $this.parent('.show-refinement').addClass('d-none');
        $this.parent('.show-refinement').remove();
    });
}

function resetSort() {
    $('body').find('.sortby .custom-select').on('change', function () {
        const $selectList = $(this);
        const $selectedOption = $selectList.children('[value="' + this.value + '"]')
            .attr('selected', true);
        const $selectedIndex = $selectedOption.index();
        const $nonSelectedOptions = $selectList.children().not($selectedOption)
            .remove()
            .attr('selected', false);

        // Reset and calculate new fixed width having only selected option as a child
        $selectList.width('auto').width($selectList.width() + 5);

        // Add options back and put selected option in the right place on the list
        $selectedOption.remove();
        $selectList.append($nonSelectedOptions);
        if ($selectedIndex >= $nonSelectedOptions.length) {
            $selectList.append($selectedOption);
        } else {
            $selectList.children().eq($selectedIndex).before($selectedOption);
        }
    });
}

function sortResetWidth() {
    const $selectedValue = $(".selected-value");
    const $customSelect = $('.custom-select');
    $selectedValue.html($customSelect.find('option:selected').text());
    $customSelect.width($selectedValue.width() + 10);
}

function filterRefinementsSearch(thisobj) {
    const $refinementGroup = thisobj.parents('.refinement');
    const $filterVal = thisobj.val().toLowerCase();
    const $refinementValues = $refinementGroup.find('.values .refinement-value');
    const $showMaxLimit = parseInt($('.show-refinement').data('count'));

    $refinementValues.each(function () {
        const $this = $(this);
        const $refinementTxt = $this.find('.refinement-label').text().toLowerCase();
        if ($refinementTxt.indexOf($filterVal) > -1) {
            $this.show();
            $this.addClass('filter-search');
        } else {
            $this.hide();
            $this.removeClass('filter-search');
        }
    });
    if ($refinementGroup.find('.filter-search').length > $showMaxLimit) {
        $refinementGroup.find('.show-refinement').removeClass('d-none');
    } else {
        $refinementGroup.find('.show-refinement').addClass('d-none');
    }
}

$(document).ready(function () {
    resetSort();
    sortResetWidth();
});

Search.initHistory = function () {
    let previousHistoryState;
    let doNotPushHistory = false;

    /**
     * Listen to all possible AJAX calls on a search page (Filters, sorting, show more, ...).
     */
    $(document).ajaxSuccess(
        function (event, xhr) {
            // Make the assumption that a product-tile being present in the response means a grid-refresh
            if (xhr.responseText.indexOf('data-titlereturn="true"') >= 0) {
                if (!doNotPushHistory) {
                    setTimeout(function () {
                        history.pushState({ reapplyFilters: true }, document.title, decodeURI($('.permalink').val()));
                    });
                }

                doNotPushHistory = false;
            }
        }
    );

    /**
     * Listen to the back and forward button of the browser to detect URL changes.
     */
    window.addEventListener('popstate', () => {
        if ((history.state && history.state.reapplyFilters)
            || (previousHistoryState && previousHistoryState.reapplyFilters)) {
            const $resetButton = $('.refinement-bar button.reset');

            previousHistoryState = history.state;

            // This async call should not cause a new history state to be pushed.
            doNotPushHistory = true;

            // Use the reset button listeners to do a refresh
            $resetButton.data('href', decodeURI(window.location.href));
            $resetButton.trigger('click');
        }
    });
};

Search.resetRefinementsBtn = function () {
    /**
     * Listen to all possible AJAX calls on a search page (Filters, sorting, show more, ...).
     */
    $(document).ajaxSuccess(
        function () {
            // Make the assumption that if there are values in ".filter-bar" that there are refinements applied,
            // Of there are, show the reset button, if not hide it
            if ($('.filter-bar .swatch-filter, .filter-bar .filter-value').length > 0) {
                $('.secondary-bar').removeClass('hide').addClass('show');
            } else {
                $('.secondary-bar').removeClass('show').addClass('hide');
            }
        }
    );
};

Search.filterRefinements = function () {
    $refinementBar.find('.refinement-filter').on('keyup touchend', function () {
        filterRefinementsSearch($(this));
    });

    $refinementBar.find('.refinement-search .cancle-icon').on('click', function () {
        filterRefinementsSearch($(this));
        $(this).parent('.refinement-search').find('.refinement-filter').val('');
    });
};

Search.applyFilter = function () {
    handleRefinementEvents();
    // Handle refinement value selection and reset click
    $('.container').on(
        'click',
        '.refinements li button, .refinement-bar button.reset, .filter-value button, .swatch-filter button, .refinement-showall-clear .clear-refinement-selection a',
        function (e) {
            e.preventDefault();
            e.stopPropagation();
            let clickedButton = $(this);
            let classNames = clickedButton.attr('class');
            if (classNames.includes('btn') && (classNames.includes('text-left'))) {
                resetTitle();
            }

            $.spinner().start();
            $(this).trigger('search:filter', e);
            var attributeId = '#' + $(this).find('span').last().attr('id');
            let url = $(this).data('href');
            const $customSelect = $('.custom-select');
            let srule;
            if (window.location.search.indexOf('srule') > 0) {
                // eslint-disable-next-line
                const query = new URLSearchParams(window.location.search);
                srule = query.get('srule');
            }
            if (srule) {
                // eslint-disable-next-line
                let oldUrl = new URLSearchParams(url)
                if (oldUrl.has('srule')) {
                    oldUrl.delete('srule');
                    oldUrl.append('srule', srule);
                    url = decodeURIComponent(oldUrl);
                } else {
                    url = url + '&srule=' + srule;
                }
            }
            $.ajax({
                url: url,
                data: {
                    page: $('.grid-footer').data('page-number'),
                    selectedUrl: url
                },
                method: 'GET',
                success: function (response) {
                    parseResults(response);
                    handleRefinementEvents();
                    Search.filterRefinements();
                    $.spinner().stop();
                    $(attributeId).parent('button').focus();
                    if ($customSelect.val() !== null) {
                        sortResetWidth();
                    }
                    resetSort();
                },
                error: function () {
                    $.spinner().stop();
                    $(attributeId).parent('button').focus();
                }
            });
        });
};

Search.resize = function () {
    let $refinementBar = $('.refinement-bar');
    // Close refinement bar and hide modal background if user resizes browser
    $(window).resize(function () {
        $('.refinement-bar, .modal-background').hide();
        $('body').removeClass('filter-active');
        $refinementBar.siblings().attr('aria-hidden', false);
        $refinementBar.closest('.row').siblings().attr('aria-hidden', false);
        $refinementBar.closest('.tab-pane.active').siblings().attr('aria-hidden', false);
        $refinementBar.closest('.container.search-results').siblings().attr('aria-hidden', false);
    });
};

Search.changePage = function () {
    var $header = $('.page > header');
    var topGrid = $searchResults.offset().top - $header.height();

    $searchResults.on('click', '.pagination .page-numbers:not(.current, .page-suggest)', function (e) {
        e.stopPropagation();
        var paginationUrl = $(this).data('url');
        e.preventDefault();

        $.spinner().start();
        $(this).trigger('search:changePage', e);
        $.ajax({
            url: paginationUrl,
            data: { selectedUrl: paginationUrl },
            method: 'GET',
            success: function (response) {
                $('.product-grid').empty().html(response);
                // Animate to top of grid
                $('html, body').animate({
                    scrollTop: topGrid
                }, 500);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
};

Search.sort = function () {
    // Handle sort order menu selection
    $('.container,.mobile-sort').on('change', '[name=sort-order]', function (e) {
        e.preventDefault();

        $.spinner().start();
        $(this).trigger('search:sort', this.value);
        $.ajax({
            url: this.value,
            data: { selectedUrl: this.value },
            method: 'GET',
            success: function (response) {
                $('.product-grid').empty().html(response);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
};

Search.infiniteScroll = function () {
    var $prodGrid = $('.search-results').find('.product-grid');
    var loadingElement = '<div class="infinitescroll-loader"></div>';

    $searchResults.addClass('infinite-scroll-enabled');

    $(window).data('ajaxready', true).on('scroll', function (event) {
        var prodGridBottom = $prodGrid.offset().top + $prodGrid.outerHeight();

        if ($(window).data('ajaxready') === false) {
            return;
        }

        if ($(window).scrollTop() + $(window).height() >= prodGridBottom) {
            $(window).data('ajaxready', false);
            event.stopPropagation();

            var showMoreUrl = $('.product-grid .infinitescroll-data').data('showmoreurl');

            if (showMoreUrl) {
                $('.show-more button').trigger('search:showMore', event);
                var $gridFooter = $('.search-results').find('.grid-footer');
                $gridFooter.append(loadingElement);
                $gridFooter.find('.infinitescroll-loader').spinner().start();
                $.ajax({
                    url: showMoreUrl,
                    data: { selectedUrl: showMoreUrl },
                    method: 'GET',
                    success: function (response) {
                        $gridFooter = $('.search-results').find('.grid-footer');
                        $gridFooter.replaceWith(response);

                        updateSortOptions(response);

                        $prodGrid = $('.search-results').find('.product-grid');
                        prodGridBottom = $prodGrid.offset().top + $prodGrid.outerHeight();
                        $(window).data('ajaxready', true);
                    },
                    error: function (err) {
                        console.log(err);
                        $prodGrid = $('.search-results').find('.product-grid');
                        prodGridBottom = $prodGrid.offset().top + $prodGrid.outerHeight();
                        $(window).data('ajaxready', true);
                    }
                });
            }
        }
    });
};

// Store Toggle
$('body').on('change', '.storeToggle', function () {
    $('.store-switch').find('.storeToggle').not(this).prop('checked', this.checked);
    let isChecked = $(this).is(":checked");
    const storeID = $('.store-name').data('storeid');
    if (!storeID) {
        $(this).removeClass('refinement-loading');
        $('#modal-store-locator.check-store-availability-global').click();
        return;
    }

    // prevents multi-click causing refinement error
    $(this).parent('.store-toggle').addClass('refinement-loading');

    var storeParam = isChecked ? storeID : 'web';
    // eslint-disable-next-line
    let queryParams = new URLSearchParams(window.location.search);
    queryParams.delete('storeid');
    if (queryParams.size > 0) {
        window.location.href = window.location.origin + window.location.pathname + '?' + queryParams.toString() + '&storeid=' + storeParam;
    } else {
        window.location.href = window.location.origin + window.location.pathname + '?storeid=' + storeParam;
    }

    // renable toggle in case something doesn't reload page
    $(this).removeClass('refinement-loading');
});

// prevents multi-click causing refinement error
$('body').on('click', '.store-toggle.refinement-loading .custom-control-label, .store-toggle.refinement-loading .custom-control-input', function (event) {
    event.preventDefault();
});

$('body').on('click', '.image-container-url', function () {
    // select_item: BW-4650
    window.dataLayer.push($(this).data('gtm'));
});
module.exports = Search;
