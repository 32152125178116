'use strict';

module.exports = $('body').on('click keydown', '.product-tile .play-button, .product-detail .play-sound', function (e) {
    if (e.type === 'click' || e.which === 13) {
        e.preventDefault();
        const $container = $(this).closest('.product-detail, .product-tile');
        const $audioId = `audio-${$container.data('pid')}`;
        const $audioElement = $(`.${$audioId}`);
        if ($audioElement.length) {
            const $sounds = document.getElementsByTagName('audio');
            for (let i = 0; i < $sounds.length; i++) {
                if ($sounds[i] !== $audioElement[0] && $sounds[i].paused === false) {
                    $sounds[i].pause();
                    $sounds[i].currentTime = 0;
                }
            }
            if ($audioElement[0].paused === false) {
                // Audio is already playing, so restart from the beginning
                $audioElement[0].currentTime = 0;
            } else {
                // Audio is not playing, so start playing from the beginning
                $audioElement[0].play();
            }
        }
    }
}).on('keydown', function (e) {
    if (e.which === 19) {
        //  19 is Pause/Break
        const $sounds = document.getElementsByTagName('audio');
        for (let i = 0; i < $sounds.length; i++) {
            if ($sounds[i].paused === false) {
                $sounds[i].pause();
                $sounds[i].currentTime = 0;
            }
        }
    }
});
