'use strict';

var processInclude = require('base/util');

$(document).ready(function () {
    processInclude(require('base/productTile'));
    processInclude(require('./product/wishlistHeart'));

    let isBearBuilder = $('body').find('#page-bear-builder').length > 0;

    if (!isBearBuilder) {
        $('body').on('mouseenter', '.tile-image', function () {
            var altImage = $(this).attr('data-alt-img');
            if (altImage !== 'null' && altImage !== '') {
                $(this).attr('src', altImage);
            }
        }).on('mouseout', '.tile-image', function () {
            var realImage = $(this).attr('data-src');
            $(this).attr('src', realImage);
        });
    }
});
